/**
 * Grid Variables - CH
 */
/**
 * Custom SASS Mixins - CH
 */
.map {
  position: relative;
  max-width: 100%;
  border: 1px solid none;
  background: transparent;
  overflow: hidden;
}

.map .map-canvas {
  position: relative;
}

.map img {
  max-width: none;
}

.map .MicrosoftMap .infobox-close {
  position: static;
  float: right;
}

.map .MicrosoftMap .Infobox .infobox-body {
  height: 100%;
}

.map .MicrosoftMap .Infobox .infobox-info {
  height: 100%;
  box-sizing: border-box;
}

.map {
  width: 100%;
  height: 100% !important;
}

@media (min-width: 1920px) {
  .map {
    padding: 0;
  }
}

@media (max-width: 1199px) {
  .map {
    min-height: 70rem;
  }
}

@media (max-width: 991px) {
  .map {
    min-height: 60rem;
  }
}

.map.map--building {
  height: 46rem !important;
  min-height: 46rem;
}

.map.map--building .gm-style-iw__content .btn.btn-link {
  display: none;
}

.map .component-content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.map .gm-style-mtc,
.map button[aria-label='Toggle fullscreen view'],
.map .gm-svpc {
  display: none;
}

.map .gm-bundled-control {
  top: 35px;
  left: auto !important;
  bottom: auto !important;
  right: 52px !important;
  margin: 0 !important;
}

.map .gm-bundled-control > div {
  top: 0 !important;
}

.map .gm-bundled-control button img {
  right: 0;
  left: auto !important;
}

@keyframes moveIn {
  0% {
    transform: scale3d(0.3, 0.3, 0.3);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

.map .gm-style-iw {
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
}

.map .gm-style-iw > div {
  width: 100% !important;
  max-width: 100% !important;
}

.map .gm-style-iw img {
  width: 320px;
}

.map .gm-style-iw__outer {
  animation: moveIn 0.3s ease-in-out;
}

.map .gm-style-iw__wrapper {
  position: absolute;
  bottom: -1.5rem;
  width: 32rem;
  transform: translateX(-41%);
  background-color: #FFFFFF;
}

.map .gm-style-iw__wrapper:after {
  display: block;
  position: absolute;
  top: 100%;
  left: 50%;
  width: 3rem;
  height: 3rem;
  transform: translate(-50%, -50%) rotateZ(45deg);
  background-color: #FFFFFF;
  content: '';
  z-index: -1;
}

.map .gm-style-iw__close {
  position: absolute;
  top: 0;
  right: 0;
  width: 4rem;
  height: 4rem;
  transition: background-color 0.3s ease;
  background-color: rgba(0, 0, 0, 0.6);
  cursor: pointer;
}

.map .gm-style-iw__close:hover {
  background-color: #000000;
}

.map .gm-style-iw__close .mv-icon {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 1.5rem;
  height: 1.5rem;
  transform: translate(-50%, -50%);
  transition: all 0.3s ease;
  background-image: url("../images/icons/icon-close--white.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  vertical-align: middle;
}

.map .gm-style-iw__image {
  width: 100%;
  height: 18rem;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.map .gm-style-iw__image img {
  display: none;
}

.map .gm-style-iw__content {
  padding: 3rem;
}

.map .gm-style-iw__content p {
  margin: 0;
}

.map .gm-style-iw__content .mv-body-4 {
  margin-bottom: 0.875em;
}

.map .gm-style-iw__content .mv-subheading-3 {
  margin-bottom: 0.79167em;
  color: #000;
}

.map .gm-style-iw__content .btn.btn-link {
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
  font-size: 1.125em;
}

@media (max-width: 991px) {
  .map .gm-style-iw__wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }
  .map .gm-style-iw__close {
    background: none;
  }
  .map .gm-style-iw__close .mv-icon {
    transition: opacity 0.3s ease;
    background-image: url("../images/icons/icon-close--black.svg") !important;
    opacity: 0.38;
  }
  .map .gm-style-iw__close:hover {
    background: none;
  }
  .map .gm-style-iw__close:hover .mv-icon {
    opacity: 1;
  }
  .map .gm-style-iw__image {
    flex-basis: 11.5rem;
    flex-grow: 0;
    flex-shrink: 0;
    max-width: 11.5rem;
    height: auto;
  }
  .map .gm-style-iw__content {
    flex-basis: auto;
    flex-grow: 1;
    flex-shrink: 1;
    max-width: none;
    padding: 0;
  }
  .map .gm-style-iw__content .mv-body-4 {
    margin-bottom: 0.78571em;
    padding: 1.14286em 2.14286em 0em 1.28571em;
    font-size: 0.875em;
  }
  .map .gm-style-iw__content .mv-subheading-3 {
    margin-bottom: 1em;
    padding: 0em 1.875em 0em 1.125em;
    font-size: 1em;
    line-height: 1.25em;
  }
  .map .gm-style-iw__content .btn.btn-link {
    display: block;
    padding: 0.71429em 2em 0.92857em 1.14286em;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    color: #55565b;
    font-family: 'Roboto';
    font-size: 0.875em;
    font-weight: 400;
    line-height: 1em;
    text-align: right;
    text-transform: lowercase;
  }
  .map .gm-style-iw__content .btn.btn-link:after {
    right: 0.71429em;
    width: 0.92857em;
    height: 0.57143em;
    transform: translateY(-50%) rotate(-90deg);
    background-color: #FFFFFF;
    background-image: url("../images/icons/icon-nav-arrow.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: auto;
  }
}

.map div div div div div div div div div {
  cursor: grab !important;
}
